import React, { lazy } from 'react';
import ReactDOM from 'react-dom';
import Suspense from './suspense/Suspense';

const App = lazy(() => import('./App'));
const Footer = lazy(() => import('./footer/Footer'));

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}

ReactDOM.render(
  <Suspense>
    <App />
  </Suspense>,
  document.getElementById('app')
);

ReactDOM.render(
  <Suspense>
    <Footer />
  </Suspense>,
  document.getElementById('footer')
);
